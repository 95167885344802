<template>
  <!-- 个人保险 -->
  <div class="increaseWealth">
    <Crumbs :item="title" />
    <div class="banner">
      <div class="banner_box">
        <!-- <div v-for="item in banner" :key="item.title"></div> -->
        <el-carousel
          :interval="5000"
          height="14vw"
          arrow="never"
          :autoplay="false"
          trigger="click"
          @change="bannerChange"
          :initial-index="id"
        >
          <el-carousel-item v-for="item in list" :key="item.categoryName">
            <!-- <h3>{{ item }}</h3> -->
            <div class="pic">
              <div class="banner_title">{{ item.categoryName }}</div>
              <!-- <p>{{ item.text1 }}</p> -->
              <p>
                {{ item.categoryText }}
              </p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- tab栏 -->
    <div class="tabs">
      <!--      <el-tabs v-model="activeName" @tab-click="handleClick">-->
      <!--        <el-tab-pane label="全部" name="all"></el-tab-pane>-->
      <!--        <el-tab-pane label="热门产品" name="hot"></el-tab-pane>-->
      <!--      </el-tabs>-->

      <div class="search">
        <el-input
          clearable
          v-model="value"
          placeholder="输入关键字搜索"
        ></el-input>
        <img @click="submit" src="~@/static/images/search.png" alt="" />
      </div>
    </div>
    <main
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-background="rgba(181, 218, 230, 0.8)"
    >
      <ul class="main">
        <template v-if="length !== 0">
          <li
            v-for="(item, index) in plan"
            :key="index"
            @click="details(item.zid)"
          >
            <img :src="item.productImage" alt="" />
            <div>
              <p>{{ item.productTitle }}</p>
              <div class="main_box">
                <ul class="introduce">
                  <li v-html="item.productSummary"></li>
                  <!-- <li v-html="item.productExample"></li>
                <li v-html="item.productFanwei"></li>
                <li v-html="item.productFeature"></li> -->
                </ul>
              </div>
            </div>
          </li>
        </template>
        <template v-else>
          <empty></empty>
        </template>
      </ul>
      <!--      <Paging :total="total" :length="length" :pageNum="pageNum" :pages="pages"></Paging>-->
      <div class="paging" v-if="show">
        <!-- <p v-if="tab == 1">共有{{ data.length }}条记录</p> -->
        <p>共有{{ total }}条记录</p>
        <div>
          <img
            @click="getPageNumAReduction()"
            src="~@/static/images/l_arrow@2x.png"
            alt=""
          />
          <span>{{ length === 0 ? 0 : pageNum }}/{{ pages }}</span>
          <img
            @click="getPageNumAdd()"
            src="~@/static/images/ic_arrow_black@2x.png"
            alt=""
          />
        </div>
      </div>
    </main>
    <!--        <main v-else>-->
    <!--          <empty></empty>-->
    <!--        </main>-->
  </div>
</template>

<script>
import empty from "@/components/empty";

import { productListPre, getProductCategory } from "@/api/personal";
import Crumbs from "@/components/crumbs.vue";

export default {
  name: "increaseWealth",
  data() {
    return {
      dataText: "", //定义
      title: null,
      loading: false,
      fullscreenLoading: false,
      plan: [],
      length: null,
      activeName: "all",
      productChannel: [],
      total: 0,
      pageNum: 0,
      pages: 0,
      tab: "",
      value: "",
      list: [],
      id: 0,
      zid: 0,
      categoryType: null,
      productHotCategory: null,
      hotZid: 0,
      hotId: 0,
      showZid: null, //需要显示的产品zid
      show: true,
    };
  },
  components: { Crumbs, empty },
  created() {
    console.log(this.$route.query);
    this.showZid = this.$route.query.zid;
    this.show = this.$route.query.zid ? false : true;
    // this.loading = true;
    if (sessionStorage.getItem("increaseWealthInfo")) {
      //如果有就读取缓存里面的数据
      this.pageNum = sessionStorage.getItem("pageNum");
      // sessionStorage.removeItem("increaseWealthInfo");
    } else {
      this.pageNum = 1;
      //这个主要是从其他页面第一次进入列表页，清掉缓存里面的数据
      sessionStorage.removeItem("pageNum");
    }
    console.log("传过来的zid", this.$route.query.productHotCategory);
    this.hotZid = this.$route.query.productHotCategory;

    this.categoryType = Number(this.$route.query.categoryType);
    console.log("id", this.$route.query.index);
    console.log("categoryType", this.$route.query.categoryType);
  },
  mounted() {
    console.log("mounted", this.categoryType);
    // setTimeout(() => {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth",
    //   });
    // }, 0);

    this.bannerList(this.categoryType);
    this.productHotCategory = this.$route.query.productHotCategory;
    console.log(this.productHotCategory);
    console.log(this.list);
  },

  methods: {
    goTop() {
      window.scrollTo({
        top: 500,
        behavior: "smooth",
      });
    },
    // 下一页
    getPageNumAdd() {
      if (this.pageNum < this.pages) {
        this.pageNum = this.pageNum + 1;
        this.request(this.pageNum);
        this.goTop();
      } else {
        this.$message.warning("已经是最后一页了");
        console.log("no");
      }
    },
    // 上一页
    getPageNumAReduction() {
      if (this.pageNum <= 1) {
        this.$message.warning("已经是第一页了");
        this.goTop();
      } else {
        this.pageNum = this.pageNum - 1;
        this.request(this.pageNum);
      }
    },
    openFullScreen1() {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
      }, 2000);
    },
    bannerChange(e) {
      this.openFullScreen1();
      console.log("00", e);
      this.id = e;
      if (this.productHotCategory) {
        this.hotZid = this.list[this.id].zid;
      }
      console.log("this.zid", this.zid);
      console.log("this.zid2", this.list[this.id].zid);
      sessionStorage.setItem("index", this.id); //存zid
      // this.fullscreenLoading = true;
      // this.productHotCategory
      //     ? this.$router.replace({
      //       path: `/increaseWealth`,
      //       query: {
      //         productHotCategory: this.id,
      //         categoryType: this.categoryType,
      //       },
      //     })
      //     : this.$router.replace({
      //       path: `/increaseWealth`,
      //       query: {index: this.id, categoryType: this.categoryType},
      //     });
      console.log(this.hotZid);
      this.productHotCategory
        ? this.requestHot(this.pageNum, this.list[this.id].zid)
        : this.requestList(this.pageNum, this.list[this.id].zid);
      console.log("e", this.id);
    },
    submit() {
      console.log(this.value);
      this.productHotCategory
        ? this.request1(this.pageNum, this.value)
        : this.request(this.pageNum, this.value);
    },

    async bannerList(num) {
      // this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getProductCategory({
          timeStamp,
          sign,
          modelType: "pre",
          pageSize: 6,
          categoryType: num,
        });
        console.log("财富增值List", res.data);
        this.id = Number(this.$route.query.index || this.id);

        if (res.data.code == 200) {
          console.log("cs", res.data);
          this.list = res.data.data;

          // this.zid = this.list[this.id].zid;
          if (!this.productHotCategory) {
            console.log("this.list[this.id].zid", this.list[this.id].zid);
            this.zid = this.list[this.id].zid;
            console.log("this.list", this.list[this.id].zid);
          } else {
            this.zid = this.hotZid;
          }
          console.log(this.zid);
          if (this.productHotCategory) {
            this.id = this.list.findIndex((item) => {
              console.log(item);
              return item.zid == this.hotZid;
            });
          }
          console.log("this.id", this.id);
          // console.log("this.list", this.list[this.id].zid);
          this.title = [
            {
              title: "产品中心",
              link: ``,
            },
            {
              title: this.categoryType == 0 ? "个人保险" : "热销新品",
              link: this.productHotCategory
                ? `/increaseWealth?productHotCategory=${this.hotZid}&categoryType=${this.categoryType}`
                : `/increaseWealth?index=0&categoryType=${this.categoryType}`,
            },
            {
              title: `${this.list[this.id].categoryName}`,
              link: this.productHotCategory
                ? `/increaseWealth?productHotCategory=${this.hotZid}&categoryType=${this.categoryType}`
                : `/increaseWealth?index=${this.id}&categoryType=${this.categoryType}`,
            },
          ];
        }
        console.log(1111, this.id);
        console.log(this.productHotCategory);
        this.productHotCategory
          ? this.requestHot(this.pageNum, this.hotZid)
          : this.requestList(this.pageNum, this.list[this.id].zid);
      } catch (error) {
        console.log(error);
      }
      // this.loading = false;
    },
    //个人
    async request(num, title) {
      console.log("zid", this.zid);
      this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await productListPre({
          timeStamp,
          sign,
          // productHotCategory:0
          modelType: "pre",
          pageSize: 6,
          productCategory: this.zid,
          productHot: 0,
          pageNum: num,
          productTitle: title,
        });
        console.log("财富增值", res.data);
        if (res.data.code == 200) {
          console.log(res);
          // if (res.data.rows == []) {
          //   this.dataText = "暂无数据";
          // }
          this.total = res.data.total;
          this.plan = res.data.rows;
          this.length = this.plan.length;
          this.pages = res.data.pages;
          this.pageNum = res.data.pageNum;
          // sessionStorage.removeItem("increaseWealthInfo");

          // this.plan.forEach((item) => {
          //   if (item.productHot === true) {
          //     this.data.push(item);
          //   }
          // });
          // console.log("data", this.data);
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500 ||
          res.data.code == 606
        ) {
          this.$router.replace("/failure");
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    async requestList(num, zid, title) {
      console.log("zid", this.zid);
      this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await productListPre({
          timeStamp,
          sign,
          modelType: "pre",
          pageSize: 6,
          productCategory: zid,
          productHot: 0,
          pageNum: num,
          productTitle: title,
          // productHotCategory:0
        });
        console.log("财富增值", res.data);
        if (res.data.code == 200) {
          console.log(res);
          this.total = res.data.total;
          this.data = res.data.rows.findIndex(
            (item) => item.zid == this.showZid
          );
          console.log("res.data.rows", res.data.rows);
          this.showZid
            ? this.plan.push(res.data.rows[this.data])
            : (this.plan = res.data.rows);
          // this.plan = res.data.rows
          console.log(this.data);
          console.log(this.plan);

          this.length = this.plan.length;
          this.pages = res.data.pages;
          this.pageNum = res.data.pageNum;
          sessionStorage.removeItem("index");
          // if (!this.plan[0]) {
          //   this.$router.replace("/found404");
          // }
          // sessionStorage.removeItem("increaseWealthInfo");
          // this.plan.forEach((item) => {
          //   if (item.productHot === true) {
          //     this.data.push(item);
          //   }
          // });
          // console.log("data", this.data);
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500 ||
          res.data.code == 606
        ) {
          this.$router.replace("/failure");
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    //热销
    async request1(num, title) {
      this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await productListPre({
          timeStamp,
          sign,
          modelType: "pre",
          pageSize: 6,
          // productCategory: this.zid,
          productHotCategory: this.zid,
          productHot: 1,
          pageNum: num,
          productTitle: title,
        });
        console.log("热销", res.data);
        if (res.data.code == 200) {
          this.total = res.data.total;
          this.plan = res.data.rows;
          this.length = this.plan.length;
          this.pages = res.data.pages;
          this.pageNum = res.data.pageNum;
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500 ||
          res.data.code == 606
        ) {
          this.$router.replace("/failure");
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    async requestHot(num, zid, title) {
      this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await productListPre({
          timeStamp,
          sign,
          modelType: "pre",
          pageSize: 6,
          // productCategory: this.zid,
          productHotCategory: this.hotZid,
          productHot: 1,
          pageNum: num,
          productTitle: title,
        });
        console.log("热销", res.data);
        if (res.data.code == 200) {
          this.total = res.data.total;
          this.data = res.data.rows.findIndex(
            (item) => item.zid == this.showZid
          );

          // this.plan.push(res.data.rows[this.data]);
          // this.plan = res.data.rows;
          this.showZid
            ? this.plan.push(res.data.rows[this.data])
            : (this.plan = res.data.rows);
          this.length = this.plan.length;
          this.pages = res.data.pages;
          this.pageNum = res.data.pageNum;
          sessionStorage.removeItem("index");
          if (!this.plan[0]) {
            this.$router.replace("/found404");
          }
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500 ||
          res.data.code == 606
        ) {
          this.$router.replace("/failure");
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    //详情
    details(zid) {
      console.log("zid", zid);
      sessionStorage.setItem("DETAILS_ZID", zid); //存zid
      sessionStorage.setItem("DETAILS_CATEGORYTYPE", this.categoryType); //存
      sessionStorage.setItem("FALLBACK", this.list[this.id].categoryName); //存
      sessionStorage.setItem("FALLBACKID", this.id); //存
      sessionStorage.setItem("pageNum", this.pageNum);
      this.$router.push({
        // path: `/increaseWealth/increaseWealthDetails?categoryType=${this.categoryType}&index=${this.id}&zid=${zid}`,
        path: `/preview/productCenter/series/details?categoryType=${this.categoryType}&zid=${zid}`,
        // query: {
        //   zid,
        // },
      });
    },
  },
  beforeDestroy() {
    sessionStorage.removeItem("increaseWealthInfo");
  },
  destroyed() {
    sessionStorage.removeItem("increaseWealthInfo");
  },
};
</script>

<style scoped lang="less">
li {
  list-style: none;
}

.increaseWealth {
  .crumbs {
    background: #003781;
  }

  .banner {
    width: 100%;

    .banner_box {
      width: 80vw;
      margin: 2rem auto;

      .pic {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .banner_title {
        margin: 3rem 0;
        font-size: 5rem;
      }
      p {
        font-size: 1.5rem;
        // height: 3rem;
        line-height: 3rem;
      }

      p:last-child {
        width: 70vw;
        text-align: center;
      }

      .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
      }

      /deep/ .el-carousel--horizontal {
        overflow-x: hidden;
        width: 100%;
      }

      /deep/ .el-carousel__container {
        position: relative;
        height: 300px;
        width: 100%;
      }

      /deep/ .el-carousel__button {
        display: block;
        opacity: 0.48;
        width: 5px;
        height: 5px;
        background-color: #ccc;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 50%;
      }

      /deep/ .el-carousel__indicator.is-active button {
        // opacity: 1;
        background: #003781;
      }

      /deep/ .el-carousel__arrow {
        width: 6rem;
        height: 6rem;
        cursor: pointer;
        // background-color: rgba(44, 56, 70, 0.11);
        background-color: #303133;
        opacity: 0.3;

        &:hover {
          opacity: 0.8;
        }
      }

      /deep/ .el-carousel__arrow i {
        font-size: 5rem;
        color: #000;
      }

      // .el-carousel__item:nth-child(2n) {
      //   background-color: #99a9bf;
      // }

      // .el-carousel__item:nth-child(2n + 1) {
      //   background-color: #d3dce6;
      // }
    }
  }

  .tabs {
    //display: flex;
    //justify-content: center;
    //align-items: center;
    margin: 3rem 0;

    > .search {
      width: 80vw;
      justify-content: end;
      margin: auto;
      display: flex;
      align-items: center;

      /deep/ .el-input__inner {
        border: none !important;
        width: 23rem !important;
        border-bottom: 1px solid !important;
        border-radius: 0 !important;
      }

      /deep/ .el-input {
        width: auto;
      }

      > img {
        width: 4rem;
        height: 4rem;
        margin-left: 1.5rem;
        cursor: pointer;
        margin-right: 10rem;
      }
    }
  }

  > main {
    width: 100%;
    //height: 71rem;
    height: auto;
    padding: 10rem 0;
    background-color: #b5dae6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > .main {
      width: 70vw;
      height: auto;
      // border: 1px solid #000;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      align-content: space-between;

      > li {
        background-color: #fff;
        //height: 27vw;
        height: 60rem;
        width: 31%;
        cursor: pointer;
        margin: 0 4.7rem 5rem 0;

        > img {
          width: 100%;
          //height: 55%;
          height: 29rem;
        }

        > div {
          padding: 2rem;
          box-sizing: border-box;
          //height: 45%;

          > p {
            font-size: 2.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          > .main_box {
            display: flex;
            justify-content: space-between;
            margin: 2rem 0;

            > .introduce {
              width: 100%;
              //overflow: hidden;
              //text-overflow: ellipsis;
              //display: -webkit-box;
              //-webkit-line-clamp: 5;
              //-webkit-box-orient: vertical;

              > li {
                font-size: 1.5rem;
                margin: 0.5rem 0;
                white-space: nowrap;

                > p {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                //p{
                //  //text-overflow: -o-ellipsis-lastline;
                //  overflow: hidden;
                //  text-overflow: ellipsis;
                //  display: -webkit-box;
                //  -webkit-line-clamp: 5;
                //  -webkit-box-orient: vertical;
                //}
              }
            }

            > .channel {
              > li {
                display: flex;
                align-items: center;
                margin: 0.5rem 0;
                white-space: nowrap;

                > p {
                  width: 0.3rem;
                  height: 1.5rem;
                  background: #003781;
                  margin-right: 1rem;
                }

                > div {
                  font-size: 1.5rem;
                }

                > img {
                  width: 13px;
                  height: 10px;
                  margin-right: 10px;
                }

                > .link {
                  color: #003781;
                }
              }
            }
          }
        }
      }

      > li:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  /deep/ .el-tabs__nav-wrap::after {
    width: 0;
  }

  /deep/ .el-tabs__nav-scroll {
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
}
</style>